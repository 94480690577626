import {ReactElement, useEffect, useState} from 'react';
import {RouteComponentProps} from '@reach/router';
import {IPromotion} from '../client/dataModels/IPromotion';
import {getPromotions} from '../client/promotionClient';
import {Stack} from '../components/common/Stack';

export const PromotionsScreen = (
  _: RouteComponentProps,
): ReactElement<RouteComponentProps> => {
  const [promotions, setPromotions] = useState<IPromotion[] | null>(null);

  useEffect(() => {
    getPromotions().then((p) => {
      setPromotions(p);
    });
  }, []);

  return (
    <Stack>
      {!!promotions && promotions.map((promotion) => JSON.stringify(promotion))}
    </Stack>
  );
};
