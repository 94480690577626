import React from 'react';

export function mapDefinedChildren<TRet, TArg>(
  children: TArg | TArg[],
  fn: (el: TArg, index: number, last: boolean) => TRet,
): React.ReactNode {
  let count = 0;

  React.Children.forEach(children, (ch) => {
    if (ch) {
      count += 1;
    }
  });

  let index = 0;
  return React.Children.map<TRet | null, TArg>(children, (ch: TArg) => {
    if (ch) {
      index += 1;
      return fn(ch, index - 1, index === count);
    }
    return null;
  });
}
