import {CSSProperties, ReactElement, ReactNode} from 'react';

const styles = {
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as CSSProperties,
};

interface CenterFloatProps {
  children: ReactNode;
}

export const CenterFloat = ({children}: CenterFloatProps): ReactElement => (
  <div style={styles.container}>{children}</div>
);
