export enum Color {
  White = '#FFFFFF',
  LightGrey = '#DDDDDD',
  DarkGrey = '#555555',
  Black = '#333333',
  Blue = '#7BDFF2',
  Celeste = '#B2F7EF',
  Cream = '#EFF7F6',
  Pink = '#F7D6E0',
  Red = '#fe3744',
}
