import {CSSProperties, ReactElement} from 'react';
import {Color} from '../../theme/Color';
import {Dimension} from '../../theme/Dimension';

interface Props {
  color?: Color;
  verticalMargin?: Dimension;
  horizontalMargin?: Dimension;
}

export const HLine = ({
  color = Color.DarkGrey,
  horizontalMargin = Dimension.None,
  verticalMargin = Dimension.Small,
}: Props): ReactElement => {
  const style: CSSProperties = {
    height: '1px',
    width: '100%',
    flex: 'auto',
    marginLeft: horizontalMargin,
    marginRight: horizontalMargin,
    marginTop: verticalMargin,
    marginBottom: verticalMargin,
    backgroundColor: color,
  };

  return <div style={style} />;
};
