import {ReactElement, useCallback, useEffect, useState} from 'react';
import {Redirect, RouteComponentProps} from '@reach/router';
import {useAuthenticationContext} from '../hooks/useAuthentication';
import {Routes} from '.';
import {CenterFloat} from '../components/common/CenterFloat';
import {Container} from '../components/common/Container';
import {Dimension} from '../theme/Dimension';
import {Color} from '../theme/Color';
import {H1} from '../components/semantics/H1';
import {StyledText} from '../components/common/StyledText';
import {Stack} from '../components/common/Stack';
import {Input} from '../components/common/Input';
import {Button} from '../components/common/Button';
import {HLine} from '../components/common/HLine';
import {VSpace} from '../components/common/VSpace';

export const LoginScreen = (
  _: RouteComponentProps,
): ReactElement<RouteComponentProps> => {
  const {user, login} = useAuthenticationContext();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    setHasError(false);
  }, [email, password]);

  const onLogin = useCallback(async () => {
    try {
      await login(email, password);
    } catch {
      setHasError(true);
    }
  }, [email, password]);

  if (user) {
    return <Redirect noThrow={true} to={Routes.Home} />;
  }

  return (
    <CenterFloat>
      <Container
        borderRadius={Dimension.Small}
        color={Color.Celeste}
        noFlex
        verticalPadding={Dimension.Normal}
        horizontalPadding={Dimension.Large}>
        <Stack separatorComponent={<HLine />} itemSpacing={Dimension.Tiny}>
          <H1 textAlign="center">
            <StyledText type="h1">Login</StyledText>
          </H1>
          <Stack itemSpacing={Dimension.Tiny}>
            <Input label="Email" value={email} onChange={setEmail} />
            <Input
              label="Password"
              type="password"
              value={password}
              onChange={setPassword}
              invalid={hasError}
            />
            <VSpace size={Dimension.None} />
            <Button onPress={onLogin}>Login</Button>
          </Stack>
        </Stack>
      </Container>
    </CenterFloat>
  );
};
