import {CSSProperties, ReactElement, ReactNode} from 'react';
import {Color} from '../theme/Color';

const styles = {
  container: {
    height: '100vh',
    width: '100vw',
    background: Color.White,
    padding: 0,
    margin: 0,
  } as CSSProperties,
};

interface ScreenProps {
  children: ReactNode;
}

export const Screen = ({children}: ScreenProps): ReactElement => (
  <div style={styles.container}>{children}</div>
);
