import React, {CSSProperties} from 'react';

type textAlign = 'left' | 'right' | 'center';

export interface SemanticProps {
  children: React.ReactNode;
  textAlign?: textAlign;
}

const styles = {
  left: {
    textAlign: 'left',
  } as CSSProperties,
  right: {
    textAlign: 'right',
  } as CSSProperties,
  center: {
    textAlign: 'center',
  } as CSSProperties,
};

export const getSemanticStyles = ({
  textAlign,
}: SemanticProps): CSSProperties => {
  const textStyle: CSSProperties = {
    ...(textAlign ? styles[textAlign] : {}),
  };

  return textStyle;
};
