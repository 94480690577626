import {CSSProperties, ReactElement, useCallback} from 'react';
import {Color} from '../../theme/Color';
import {Dimension} from '../../theme/Dimension';
import {P} from '../semantics/P';
import {Stack} from './Stack';
import {StyledText} from './StyledText';

interface InputProps {
  value: string;
  type?: 'password';
  placeholder?: string;
  invalid?: boolean;
  label?: string;
  onChange: (value: string) => void;
}

export const Input = ({
  value,
  type,
  invalid,
  label,
  placeholder,
  onChange: superOnChange,
}: InputProps): ReactElement => {
  const style: CSSProperties = {
    lineHeight: '1.5rem',
    borderColor: invalid ? Color.Red : undefined,
  };

  const onChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    superOnChange(event.currentTarget.value);
  }, []);

  return (
    <Stack itemSpacing={Dimension.Tiny}>
      {!!label && (
        <P>
          <StyledText type="body1">{label}</StyledText>
        </P>
      )}
      <input
        placeholder={placeholder}
        type={type}
        style={style}
        value={value}
        onChange={onChange}
      />
    </Stack>
  );
};
