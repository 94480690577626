import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {StrictMode} from 'react';
import Firebase from 'firebase';
import * as dotenv from 'dotenv';

dotenv.config();

const firebaseConfig = {
  apiKey: 'AIzaSyAmBNh1eLy69IFueuAKulqs1VNxTN9MB8c',
  authDomain: 'tokyo-rain-255709.firebaseapp.com',
  databaseURL: 'https://tokyo-rain-255709.firebaseio.com',
  projectId: 'tokyo-rain-255709',
  storageBucket: 'tokyo-rain-255709.appspot.com',
  messagingSenderId: '160534446462',
  appId: '1:160534446462:web:a542ab11682cab474e732f',
  measurementId: 'G-MNK9MEK13H',
};

Firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
