export interface FontSettings {
  fontSize: string;
  lineHeight: string;
}

export interface Typography {
  h1: FontSettings;
  body1: FontSettings;
  link1: FontSettings;
  number1: FontSettings;
}
export type TextStyle = keyof Typography;

export const typography: Typography = {
  h1: {
    fontSize: '2rem',
    lineHeight: '2.1rem',
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.1rem',
  },
  link1: {
    fontSize: '1rem',
    lineHeight: '1.1rem',
  },
  number1: {
    fontSize: '1rem',
    lineHeight: '1.1rem',
  },
};
