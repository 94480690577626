import {ReactElement} from 'react';
import {Redirect, RouteComponentProps} from '@reach/router';
import {Routes} from './screens';
import {useAuthenticationContext} from './hooks/useAuthentication';
import {PromotionsScreen} from './screens/Promotions';
import {Button} from './components/common/Button';

export const ProtectedRouter = (
  _: RouteComponentProps,
): ReactElement<RouteComponentProps> => {
  const {user, logout} = useAuthenticationContext();

  if (!user) {
    return <Redirect noThrow={true} to={Routes.Login} />;
  }

  return (
    <>
      <Button onPress={logout}>Logout</Button>
      <PromotionsScreen path={Routes.Promotions} />
    </>
  );
};
