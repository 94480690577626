import {CSSProperties, ReactElement} from 'react';
import {getSemanticStyles, SemanticProps} from './common';

const style: CSSProperties = {
  margin: 0,
};

export const P = (props: SemanticProps): ReactElement => (
  <p style={{...style, ...getSemanticStyles(props)}}>{props.children}</p>
);
