import {CSSProperties, ReactElement} from 'react';
import {Color} from '../../theme/Color';
import {TextStyle, typography} from '../../theme/Typography';

const styles = {
  text: {
    flex: 0,
    color: Color.Black,
    includeFontPadding: false,
    textAlignVertical: 'center',
  } as CSSProperties,
  underline: {
    textDecorationLine: 'underline',
  } as CSSProperties,
  uppercase: {
    textTransform: 'uppercase',
  } as CSSProperties,
  wrapText: {
    flexShrink: 1,
    flexWrap: 'wrap',
  } as CSSProperties,
  shrink: {
    flexShrink: 1,
  } as CSSProperties,
};

interface StyledTextProps {
  children: string;
  type: TextStyle;
  underline?: boolean;
  uppercase?: boolean;
  wrapText?: boolean;
  shrink?: boolean;
}

export const StyledText = ({
  children,
  type,
  underline,
  uppercase,
  wrapText,
  shrink,
}: StyledTextProps): ReactElement => {
  const textStyle: CSSProperties = {
    ...styles.text,
    ...typography[type],
    ...(underline ? styles.underline : {}),
    ...(uppercase ? styles.uppercase : {}),
    ...(wrapText ? styles.wrapText : {}),
    ...(shrink ? styles.shrink : {}),
  };
  return <span style={textStyle}>{children}</span>;
};
