import {ReactElement} from 'react';
import {Router} from './Router';
import {AuthenticationProvider} from './contexts/Authentication';

const App = (): ReactElement => (
  <AuthenticationProvider>
    <Router />
  </AuthenticationProvider>
);

export default App;
