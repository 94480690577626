import {CSSProperties, ReactElement, ReactNode} from 'react';
import {Color} from '../../theme/Color';
import {Dimension} from '../../theme/Dimension';

interface ContainerProps {
  margin?: Dimension;
  borderRadius?: Dimension;
  children: ReactNode;
  color?: Color;
  noFlex?: boolean;
  verticalPadding?: Dimension;
  horizontalPadding?: Dimension;
  padding?: Dimension;
}

export const Container = (props: ContainerProps): ReactElement => {
  const {
    margin = Dimension.Normal,
    borderRadius = Dimension.None,
    color,
    children,
    noFlex = false,
    verticalPadding = props.padding || Dimension.None,
    horizontalPadding = props.padding || Dimension.None,
  } = props;

  const style: CSSProperties = {
    margin,
    borderRadius,
    backgroundColor: color,
    flex: noFlex ? 0 : 1,
    paddingTop: verticalPadding,
    paddingBottom: verticalPadding,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
  };

  return <div style={style}>{children}</div>;
};
