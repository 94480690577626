import {useContext} from 'react';

import {
  AuthenticationContextValue,
  AuthenticationContext,
} from '../contexts/Authentication';

export const useAuthenticationContext = (): AuthenticationContextValue => {
  if (AuthenticationContext === undefined) {
    throw new Error(
      'useAuthenticationContext must be used within a AuthenticationProvider',
    );
  }
  return useContext(AuthenticationContext);
};
