import firebase from 'firebase';
import ClientError from './clientError';

// const apiUrl = process.env.API_URL;

class Client {
  private getUrl = (path: string) => `http://localhost:8080/admin/${path}`;

  private getAuthorizatonHeader = async () => {
    try {
      const authToken = await firebase.auth().currentUser?.getIdToken();

      if (authToken) {
        return {
          Authorization: `Bearer ${authToken}`,
        };
      }
    } catch {
      return null;
    }
    return null;
  };

  public delete = async (path: string): Promise<Response> => {
    const authHeaders = await this.getAuthorizatonHeader();
    return fetch(this.getUrl(path), {
      headers: {
        ...authHeaders,
      },
      method: 'DELETE',
    });
  };

  public get = async <T>(path: string): Promise<T> => {
    const authHeaders = await this.getAuthorizatonHeader();
    return fetch(this.getUrl(path), {
      headers: {
        ...authHeaders,
      },
    }).then(async (data) => {
      if (data.ok) {
        try {
          return await data.json();
        } catch {
          return null;
        }
      }
      const e: ClientError = {
        code: data.status,
        name: data.statusText,
        message: await data.text(),
      };
      throw e;
    });
  };

  post = async (path: string, data: Record<string, unknown>) => {
    const authHeaders = await this.getAuthorizatonHeader();
    return fetch(this.getUrl(path), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeaders,
      },
      body: JSON.stringify(data),
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      }
      const e: ClientError = {
        code: response.status,
        name: response.statusText,
        message: await response.text(),
      };
      throw e;
    });
  };
}

export default new Client();
