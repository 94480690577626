import {CSSProperties, ReactElement, useCallback, useState} from 'react';
import {Color} from '../../theme/Color';
import {Dimension} from '../../theme/Dimension';

interface ButtonProps {
  onPress: () => void;
  children: string;
}

export const Button = ({onPress, children}: ButtonProps): ReactElement => {
  const [hover, setHover] = useState<boolean>(false);

  const onHover = useCallback(() => {
    setHover(true);
  }, [setHover]);

  const offHover = useCallback(() => {
    setHover(false);
  }, [setHover]);

  const style: CSSProperties = {
    lineHeight: Dimension.SemiSmall,
    backgroundColor: hover ? Color.LightGrey : Color.White,
    borderRadius: '0.2rem',
    borderStyle: 'none',
    boxShadow: `0px 1px 5px ${Color.DarkGrey}`,
    cursor: 'pointer',
  };

  return (
    <button
      onMouseEnter={onHover}
      onMouseLeave={offHover}
      style={style}
      onClick={onPress}>
      {children}
    </button>
  );
};
