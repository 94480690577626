import Client from './apiBaseClient';
import {IPromotion} from './dataModels/IPromotion';

interface PromotionResponse {
  promotions: IPromotion[];
}

export const getPromotions = async (): Promise<IPromotion[]> => {
  const res = await Client.get<PromotionResponse>(`promotion`);
  return res.promotions;
};
