import Client from './apiBaseClient';

export const checkIfAdminAccess = async (): Promise<boolean> => {
  try {
    await Client.get('admin/check');
    return true;
  } catch (ex) {
    return false;
  }
};
