import {CSSProperties, ReactElement} from 'react';
import {Dimension} from '../../theme/Dimension';

interface VSpaceProps {
  size?: Dimension;
}

export const VSpace = ({size = Dimension.Small}: VSpaceProps): ReactElement => {
  const style: CSSProperties = {
    height: size,
    flex: 'auto',
  };
  return <div style={style} />;
};
