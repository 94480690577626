import {ReactNode, CSSProperties, ReactElement} from 'react';
import {VSpace} from './VSpace';
import {mapDefinedChildren} from './utils';
import {Dimension} from '../../theme/Dimension';

interface Props {
  children: ReactNode;
  itemSpacing?: Dimension;
  separatorComponent?: React.ReactNode;
}

export const Stack = ({
  children,
  itemSpacing = Dimension.Normal,
  separatorComponent,
}: Props): ReactElement => {
  const separator = separatorComponent ? (
    <>
      <VSpace size={itemSpacing} />
      {separatorComponent}
      <VSpace size={itemSpacing} />
    </>
  ) : (
    <VSpace size={itemSpacing} />
  );

  const items = mapDefinedChildren(children, (ch, _, isLast) =>
    isLast ? (
      ch
    ) : (
      <>
        {ch}
        {separator}
      </>
    ),
  );

  const style: CSSProperties = {
    flex: 0,
    flexDirection: 'column',
  };

  return <div style={style}>{items}</div>;
};
