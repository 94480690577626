import {ReactElement} from 'react';
import {Router as ReachRouter} from '@reach/router';
import {Screen} from './components/Screen';
import {Routes} from './screens';
import {LoginScreen} from './screens/Login';
import {ProtectedRouter} from './ProtectedRouter';

export const Router = (): ReactElement => (
  <Screen>
    <ReachRouter>
      <LoginScreen path={Routes.Login} />
      <ProtectedRouter path={Routes.Home} />
    </ReachRouter>
  </Screen>
);
