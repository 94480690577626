import {CSSProperties, ReactElement} from 'react';
import {getSemanticStyles, SemanticProps} from './common';

const style: CSSProperties = {
  margin: 0,
};

export const H1 = (props: SemanticProps): ReactElement => (
  <h1 style={{...style, ...getSemanticStyles(props)}}>{props.children}</h1>
);
