import {useState, useEffect, useCallback, createContext} from 'react';
import Firebase from 'firebase';
import {checkIfAdminAccess} from '../client/adminClient';

type MaybeUser = Firebase.User | null;
type MaybeCredential = Firebase.auth.UserCredential | null;

export interface AuthenticationContextValue {
  user: MaybeUser;
  login: (email: string, password: string) => Promise<MaybeCredential>;
  logout: () => Promise<void>;
}

export const AuthenticationContext = createContext<AuthenticationContextValue>({
  user: null,
  login: () => new Promise((res) => res(null)),
  logout: () => new Promise((res) => res()),
});

export type AuthenticationProviderProps = {
  children: React.ReactNode;
};

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  children,
}) => {
  const [user, setUser] = useState<Firebase.User | null>(null);

  const onAuthChanged = useCallback(
    (currentUser: Firebase.User | null) => {
      setUser(currentUser);
    },
    [setUser],
  );

  const login = useCallback(async (email: string, password: string) => {
    const user = await Firebase.auth().signInWithEmailAndPassword(
      email,
      password,
    );
    const isAdmin = await checkIfAdminAccess();
    if (!isAdmin) {
      await Firebase.auth().signOut();
      // eslint-disable-next-line no-alert
      alert('You do not have admin access.');
    } else {
      // eslint-disable-next-line no-alert
      alert('You are admin');
    }
    return user;
  }, []);

  const logout = useCallback(() => Firebase.auth().signOut(), []);

  useEffect(() => {
    const unsubscribe = Firebase.auth().onAuthStateChanged(onAuthChanged);
    return unsubscribe;
  }, [onAuthChanged]);

  return (
    <AuthenticationContext.Provider value={{user, login, logout}}>
      {children}
    </AuthenticationContext.Provider>
  );
};
